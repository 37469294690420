import React from 'react';
import DemoCards from '../../../../themes/nerdify/components/DemoCards';
import academia from './reviews/academia.json';
import businesses from './reviews/businesses.json';
import college_students_1 from './reviews/college-students-1.json';
import college_students_2 from './reviews/college-students-2.json';
import college_students_3 from './reviews/college-students-3.json';
import college_students_4 from './reviews/college-students-4.json';
import college_students_5 from './reviews/college-students-5.json';
import students from './reviews/students.json';

const records = {
  "facebook": [
    {
      "date": "4 days ago",
      "name": "Veronica D.",
      "photo": "Veronica D.jpg",
      "photo2x": "Veronica D@2x.jpg",
      "recommends": true,
      "review": "In fact, am really amazed by how Nerdify tutors help you get through some tough topics. I was afraid since it was my first time using the service and l didn't know whether to believe or trust something online. But after helping me l think you guys are the best! Wonderful, affordable, prompt service. I will use it again definitely!"
    },
    {
      "date": "1 week ago",
      "name": "Sunny R.",
      "photo": "Sunny-R_.jpg",
      "photo2x": "Sunny-R_@2x.jpg",
      "recommends": true,
      "review": "I love it. They help me a lot with my chores. I choose Nerdify and trust them! They always do whatever it takes to make sure I am satisfied with the service. My favorite Nerd tutor is Mark – he is a true star! I have never ever had any trouble with him and his professional and empathetic approach is awesome. Kudos to Mark!"
    },
    {
      "date": "3 weeks ago",
      "name": "Autumn S.",
      "photo": "Autumn S.jpg",
      "photo2x": "Autumn S@2x.jpg",
      "review": "They explained me how to improve my work a few times and it was ABSOLUTELY PERFECT. They are courteous, on time and very consistent with communication. They always pay attention to details on whatever I need. The prices are also very reasonable and depend on urgency. I would recommend Nerdify to anyone whose life gets too busy sometimes to keep up with a pace. Thank you for everything!",
      "recommends": true,
      "reply": "Thank you so much, Autumn! Can't wait to help you further :)"
    },
    {
      "date": "1 month ago",
      "name": "Alejandra P.",
      "photo": "Alejandra P.jpg",
      "photo2x": "Alejandra P@2x.jpg",
      "review": "Honestly, I’m so overjoyed and happy! My whole experience was simply amazing in terms of quality, honesty and time. Plus the price was so GOOD! So yes, I officially love Nerdify and I recommend it 100%! Thank you Nerdify for being my savior at a time when I was most stressed and afraid I wouldn’t find the suitable tutor. I’ll admit, it was sort of a gamble for me personally when I first requested a Nerd because I had never tried them or a similar service before in my life. So it was basically a 50/50 that it would work. But I chose to follow my gut and trust them, and believe me I have never made a better choice. Also in terms of their support response, it says that Nerdify typically replies in minutes, and they do! So it’s not a fraud, it’s the complete opposite. I’m just so happy, and I’m definitely return to Nerdify in the future.",
      "recommends": true,
    },
    {
      "date": "1 month ago",
      "name": "Catherine K.",
      "photo": "Catherine K.jpg",
      "photo2x": "Catherine K@2x.jpg",
      "recommends": true,
      "review": "The Nerds really help me out. They have Nerds in pretty much any subject you might need help with. If you need a little tutoring help they’re here to save the day! This is great."
    },
    {
      "date": "2 months ago",
      "name": "Kasper G.",
      "photo": "Kasper G.jpg",
      "photo2x": "Kasper G@2x.jpg",
      "recommends": true,
      "review": "They’re amazing! The Nerd tutors are always 100% helpful. I’ve never ever trusted these kinds of platforms. But when I was running out of time I started looking for help and that is how I came across Nerdify. They reached out to me via text messages in a professional way that made me trust their words easily and I don’t regret it at all."
    },
    {
      "date": "2 months ago",
      "name": "Tara C.",
      "photo": "Tara C.jpg",
      "photo2x": "Tara C@2x.jpg",
      "recommends": true,
      "review": "I wanted to share my first experience using Nerdify – it was amazing. One of my friends gave me a number to text, and as soon as I texted it I got a response. The person on the other side was very helpful and careful. I will definitely use Nerdify again. These guys are quick, smart, and extremely attentive. If you use their service, YOU WILL NOT BE DISAPPOINTED!",
      "reply": "Wow, thanks for your feedback. <3"
    },
    {
      "date": "2 months ago",
      "name": "Jermain C.",
      "photo": "Jermain C.jpg",
      "photo2x": "Jermain C@2x.jpg",
      "recommends": true,
      "review": "This semester, I can honestly say it was pretty hard. With Nerdify's tutoring sessions, I was able to study and put forth better efforts towards passing my classes. Thank you guys for all the help!"
    },
    {
      "date": "2 months ago",
      "name": "Trey R.",
      "photo": "Trey R.jpg",
      "photo2x": "Trey R@2x.jpg",
      "recommends": false,
      "review": "Requested help but they notified in 30 min saying the Nerd was not available :(",
      "reply": "Dear Trey, please accept our sincere apologies for this situation. Unfortunately, sometimes due to various reasons we are unable to provide assistance. This can happen due to a heavy workload of your Nerd or some family issues. In this particular situation your Nerd experienced tech difficulties and was unable to assist you on time. We will gladly add a bonius to your account as an apology and hope you give us a second chance!"
    },
    {
      "date": "3 months ago",
      "name": "Karina L.",
      "photo": "Karina L.jpg",
      "photo2x": "Karina L@2x.jpg",
      "recommends": true,
      "review": "I’ve used Nerdify for the stuff I had trouble with. They are great at doing their job and providing tutoring help any time I need it!"
    },
    {
      "date": "3 months ago",
      "name": "Jocabed S.",
      "photo": "Jocabed S.jpg",
      "photo2x": "Jocabed S@2x.jpg",
      "recommends": true,
      "review": "This is the most convenient way to hire a person who can explain to you everything you need in the specific subject. Thanks to Nerdify I managed to get through my Biology and History courses. I never considered other companies after I found Nerdify."
    },
    {
      "date": "3 months ago",
      "name": "Curtis R.",
      "photo": "Curtis R.jpg",
      "photo2x": "Curtis R@2x.jpg",
      "recommends": true,
      "review": "Was a last-minute lifesaver... I needed someone to advise on my presentation framework and I contacted Nerdify at 6 am. They suggested me a tutor right away and I managed to make it to the deadline. Satisfied with their work but wish the price was cheaper."
    },
    {
      "date": "3 months ago",
      "name": "Talita F.",
      "photo": "Talita F.jpg",
      "photo2x": "Talita F@2x.jpg",
      "recommends": true,
      "review": "They are great, attentive and trustworthy!"
    },
    {
      "date": "3 months ago",
      "name": "Mykala F.",
      "photo": "Mykala F.jpg",
      "photo2x": "Mykala F@2x.jpg",
      "recommends": true,
      "review": "This company is absolutely 100% reliable and honest. They will text you within seconds and they make sure your emergency isn’t an emergency anymore. I will definitely be using and recommending it! Thanks again!"
    }
  ],

  "trustpilot": [
    {
      "date": "2 days ago",
      "name": "Jennifer A.",
      "score": "5",
      "title": "My honest review",
      "review": "I really like this service. With my job basically taking over my life now: working from home + keeping up with online classes became really difficult. But with Nerdify, I am confident I have a plan B once I need someone to explain me the topic."
    },
    {
      "date": "6 days ago",
      "name": "BK",
      "score": "5",
      "title": "Very happy with their work",
      "review": "I needed them for my project and they did an amazing job during a tutoring session, addressing all my concerns. I will recommend Nerdify to everyone and I’ll work with Nerds again for sure. Thank you for your help!",
    },
    {
      "date": "1 week ago",
      "name": "Lanasha D.",
      "score": "5",
      "title": "I’ve been using it for a while",
      "review": "I am always impressed with their work and help! I always make sure to be very specific and thus never had any problems with my Personal Nerd. In fact, this is one of the best websites I have ever used! Very professional, helpful, fast and accurate! You won’t be disappointed 😉"
    },
    {
      "date": "3 weeks ago",
      "name": "Brandon W.",
      "score": "5",
      "title": "Amazing Work!",
      "review": "Nothing in life is perfect and neither are they – but they are damn close to perfection. I have had over a dozen requests and I had trouble with just one, but it was refunded right away. They have excellent response time and amazing customer service. I would definitely recommend this company: they are legit and the real deal."
    },
    {
      "date": "1 month ago",
      "name": "Clara B.",
      "score": "5",
      "title": "AWESOME",
      "review": "I have used them many times and they were always the nicest, most professional and helpful tutors you will deal with. I always get a nice Nerd, who can explain the difficult topic and improve my understanding. This is an awesome experience, you can trust Nerdify!",
    },
    {
      "date": "1 month ago",
      "name": "Diana B.",
      "score": "5",
      "title": "They helped me a lot!",
      "review": "I had a business project that I was overwhelmed with finishing up and it wasn't an easy task. But the Nerd really did a good job of advising on how better to organize my work to make sense and sound more professional. I really appreciate that support updates via SMS. When I had any questions someone quickly answered regardless of the time of the day. I will definitely use this service again!",
    },
    {
      "date": "2 months ago",
      "name": "Tiffany",
      "score": "4",
      "title": "Very nice",
      "review": "Life gets extremely busy and having help is greatly appreciated. Knowing that I have Nerd tutors covering my back brings me peace!"
    },
    {
      "date": "2 months ago",
      "name": "Tom K.",
      "score": "5",
      "title": "Loving it",
      "review": "With my busy schedule being a full-time worker and full-time student I don't have a lot of time for my courses. With the help of this company, I keep my studies in order. Very professional and easy to reach. I am a returning customer. Surely I will use them again in the future."
    },
    {
      "date": "3 months ago",
      "name": "Felicia M.",
      "score": "5",
      "title": "Outstanding company",
      "review": "I was introduced to Nerdify about a year ago by a good friend of mine. After doing my research about them, I began using Nerdify and they are the greatest. I love the fact that they have tutors in any area that you may need them for. The level of communication with them is astonishing. They communicate with you throughout the entire process. All of my needs were met by Nerdify. The very most important attribute Nerdify displayed to me is professionalism, which is very exquisite. I highly recommend Nerdify."
    },
    {
      "date": "3 months ago",
      "name": "Latoya B.",
      "score": "5",
      "title": "Thanks a lot!",
      "review": "I found these guys by googling. Yes, at first I was very skeptical about the entire situation, I didn't really think.it was legit... But I was desperate and really need help with my course so I decided to give it a try. Boy, I am glad I did. These people are.very friendly and pleasant and are willing to help. I will definitely work with them again.Thank you Nerdify!!!"
    },
    {
      "date": "4 months ago",
      "name": "Mikaela F.",
      "score": "5",
      "title": "Excellent Source for Help",
      "review": "I have used Nerdify a few times now and it has been nothing but amazing. Super helpful and reliable. I love that you guys are right there when I need it. And I would recommend others to use Nerdify as well. I have referred a couple of people to you and will continue to."
    },
    {
      "date": "4 months ago",
      "name": "D B",
      "score": "5",
      "title": "Nerdify Honest Overview",
      "review": "I’ve searched forever for a team of smart and trustworthy tutors to help me get through a few courses for school, which I’ve now found with Nerdify. I trust this team 100% no doubt. The quotes are, in my opinion, perfect. Not overpriced at all which is greatly appreciated. This team is patient and will answer any and all of your questions. Nerdify is persistent and dedicated to helping you study the best they can."
    },
    {
      "date": "5 months ago",
      "name": "Marissa B.",
      "score": "5",
      "title": "Nerdify is a lifesaver",
      "review": "Nerdify has literally saved my life when it comes to school. They contact you back within seconds and always do a great job. I only had a problem once, which turned out to be just a misunderstanding. They are convenient, accurate and reliable. I highly recommend them to anyone."
    },
    {
      "date": "5 months ago",
      "name": "Helio Q.",
      "score": "5",
      "title": "Nerds are the best!",
      "review": "I have passed some of my most difficult classes thanks to the great freelancers on this platform. They are always willing to provide tutoring help any day and at any time. I truly appreciate your help."
    }
  ]
};

export default (props) => {
  return (
    <DemoCards
      titleTag={"h2"}
      records={props.records ? props.records : records}
    />
  )
};

export {
  academia,
  businesses,
  college_students_1,
  college_students_2,
  college_students_3,
  college_students_4,
  college_students_5,
  students,
};
