import React from "react";
import Text from "../../../../base_components/Text";

export const HiwTitle = <Text>Fast & Easy. Already in your phone.</Text>;

export const HiwItems = [
  {
    img: 'hiw1.svg',
    message: 'Describe a task in your own words or send a photo of instructions'
  },
  {
    img: 'hiw2.svg',
    message: 'Get the best available Nerd to explain your task'
  },
  {
    img: 'hiw3.svg',
    message: 'Approve the price and make a secure payment through the platform'
  },
  {
    img: 'hiw20.svg',
    message: 'Get assistance and become one of thousands of happy Nerdify clients'
  }
];

export const HIWItems2 = [
  {
    img: 'hiw1',
    step: "Step 1",
    message: <Text>Message us and send the details: take a photo of the instructions or tell us what you need in your own words.</Text>
  },
  {
    img: 'hiw2',
    step: "Step 2",
    message: <Text>Our automated algorithm will pick the perfect freelancer (Nerd) available to explain your task.</Text>
  },
  {
    img: 'hiw5',
    step: "Step 3",
    message: <Text>Agree to the price we calculated based on the hours your freelance Nerd will spend working with you during the session.</Text>
  },
  {
    img: 'hiw3',
    step: "Step 4",
    message: <Text>Send a prepayment: we will keep your money safe and release it to the Nerd after your session is completed.</Text>
  },
  {
    img: 'hiw6',
    step: "Step 5",
    message: <Text>Collaborate with the Nerd tutor on your task in the most suitable way for you at any time of the day.</Text>
  },
  {
    img: 'hiw4',
    step: "Step 6",
    message: <Text>Experience the benefits of on-demand personalized help from Nerds and get the desired result on time.</Text>
  },
];
