import React, { Fragment, useState, useEffect } from 'react';
import {Link, NavLink, useLocation} from 'react-router-dom';
import Holder from '../../../../themes/nerdify/components/Holder';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import {
  Header5,
  HeaderIcon,
} from './../../../../themes/nerdify/components/Header';
import { TextBack5 } from '../../blocks/TextBack'
import { Info6 } from './../../../../themes/nerdify/components/Info';
import DemoCards from '../../blocks/DemoCards';
import { Hero13 } from '../../../../themes/nerdify/components/Hero';
import BackLink from '../../../../themes/nerdify/components/BackLink';
import { Footer2 } from '../../blocks/Footer';
import {
  Faq2,
  Accordion,
  AccordionHead,
  AccordionBody,
  AccordionItem,
  AccordionIcon,
  AccordionItemHolder,
} from '../../../../themes/nerdify/components/Faq';
//import Icon from '../../../../themes/nerdify/components/Icons';

import LocalesPage from '../LocalesPage';
import classnames from 'classnames';
import Button from '../../../../base_components/Button';
import { Form } from '../../../../themes/nerdify/components/Form';
import InputError from '../../../../themes/nerdify/components/Form/InputError';

import { updateUser, checkLeadExists } from '../../../../redux/actions';

import regexps from '../../../../utils/regexps';
import { parseQuery } from '../../../../utils';

const ThankYou = (props) => {
  const { pageCfg } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const { isPatching } = useSelector((state) => state.textback);

  const [email, setEmail] = useState('');
  //const [userId, setUserId ] = useState('')
  const [errors, setErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const urlParams  = parseQuery(location.search);
  const phone  = props.location.state && props.location.state.phone || _.get(urlParams, 'to', '').split('-').join('') || '';
  const landing_type  = props.location.state && props.location.state.landing_type || _.get(urlParams, 'landing_type', '') || '';
  const source_url = props.location.state && props.location.state.source_url && decodeURIComponent(props.location.state.source_url) || decodeURIComponent(_.get(urlParams, 'source_url', '')) || '';

  useEffect(() => {
    localStorage.removeItem('leadCreated');
  }, []);

  const validate = () => {
    const errors = {
      email: !regexps.email(email.trim()) ? 'Required field' : null,
    };

    const isError = Object.keys(errors).some((item) => errors[item]);

    setErrors(errors);

    return isError;
  };

  const getUserIdHelper = async () => {
    const phone = _.get(parseQuery(window.location.search), 'to', '');

    if (phone) {
      const existsData = await dispatch(checkLeadExists(phone.split('-').join(''))).then(
        (resp) => resp.data
      );
      return existsData._id
    } else {
      return _.get(location, 'state.userId', '');
    }
  };

  const onSubmit = () => {
    const isError = validate();

    if (isError) {
      return false;
    }

    setErrors('');

    const userIdRes = getUserIdHelper();

    userIdRes.then(userId => {
      if (!userId) {
        return false;
      }

      setIsSubmitted(true);

      dispatch(updateUser(userId, email))
        .then((resp) => {
          return resp;
        })
        .catch((error) => {
          setErrors({
            server: error.text,
          });
        });
    })
  };

  const onChange = (e) => {
    const { value } = e.target;

    setEmail(value);
  };

  const isErrors = errors.server || errors.email;

  return (
    <LocalesPage {...props}>
      <div className="landing">
        <Header5>
          <Holder>
            <NavLink className="logo-link logo-link_1" to="/">
              <HeaderIcon iconName="logo-dark" theme={1} />
            </NavLink>
            {pageCfg.backLink && <BackLink path={source_url} phone={phone} />}
          </Holder>
        </Header5>

        <Hero13 subTheme={1}>
          <div className="hero-main">
            <div className="holder">
              <h1 className="hero__title">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Done! We’ve just texted you again!</>
                  )
                  : (
                    <>We'll text you<br className="mobile-only" /> in a few seconds!</>
                  )
                }
              </h1>
              <h5 className="hero__subtitle top">
                {landing_type === 'second' || landing_type === 'undelivered' ?
                  (
                    <>Did not receive our message? Please check if the phone number is correct.</>
                  )
                  : (
                    <>If you don't receive our message,<br/> please send a request again in 15 sec.</>
                  )
                }
              </h5>



              <TextBack5 {...props} subTheme={6} submitBtnTxt={'Text me now'} />
              {landing_type === 'undelivered' && (
                <div className="hero__warning">If the phone number is put in correctly, please, contact us via email at done@thenerdify.com <br className="desktop-only"/>
                 <a className="hero__link" href="mailto:done@thenerdify.com">done@thenerdify.com</a></div>
              )}

              {!isSubmitted ? (
                <>
                  <h5 className="hero__subtitle bottom">
                    Enter your email to receive Nerdify discounts and promos
                  </h5>
                  <Form className={'email-form'}>
                    <div className="input-wrapper">
                      <input
                        className={classnames('input', {
                          input__error: isErrors,
                        })}
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Email"
                        autoCapitalize="off"
                        autoCorrect="off"
                        autoComplete="email"
                        onChange={onChange}
                      />
                      {isErrors && (
                        <InputError>{errors.email || errors.server}</InputError>
                      )}
                    </div>
                    <Button
                      className={classnames('button__type6 submit-btn', {
                        submitted: isSubmitted || isPatching,
                      })}
                      disabled={isSubmitted || isPatching}
                      onClick={onSubmit}
                    >
                      {isPatching ? (
                        <ul className="loading">
                          <li />
                          <li />
                          <li />
                          <li />
                        </ul>
                      ) : (
                        <Fragment>
                          {isSubmitted ? 'Email submited' : 'Submit email'}
                        </Fragment>
                      )}
                    </Button>
                  </Form>
                </>
              ) : (
                <h5 className="hero__subtitle bottom">Thanks for sharing your email with us! <br className="desktop-only"/>
                  We will send you news about Nerdify discounts and seasonal promos.</h5>
              )}
            </div>
          </div>
        </Hero13>

        <Info6>
          <h3 className="h3 info6-title">
            We bet you'd find it important:
          </h3>
          <p className="info6-p">
            All Nerds have successfully passed our strict examination process
            and proven their
            <br className="desktop-only" /> competence. That's why students rate
            Nerds 4.7/5 on average
          </p>
          <div className="info6-rate-img" />
        </Info6>

        <DemoCards title="Check what our clients say about their Nerdify experience" titleTag={"h2"} />

        <Faq2>
          <Holder>
            <h3 className={'h3'}>FAQ</h3>
            <Accordion>
              <AccordionItemHolder>
                <AccordionItem index={1}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h6 className={'h6'}>
                      What tasks can Nerds help me with?
                    </h6>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      They can help you out with almost anything! The Nerds help with any type of task except for those that violate academic, college, university or corporate policies. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={2}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h6 className={'h6'}>
                      How do you select the right Nerd for my request?
                    </h6>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      The platform algorithm selects the best-fit Nerd to be assigned to your request by comparing different parameters: specialty, experience, rating, availability, additional skills, etc.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>

              <AccordionItemHolder>
                <AccordionItem index={3}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h6 className={'h6'}>
                      How much should I pay for using Nerdify?
                    </h6>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Getting in touch with us is free. We only charge an extra fee (11%) in addition to the cheapest possible rate of the Nerd assigned to work with you. You'll always agree to the final cost before proceeding with the request! It's up to you whether to accept the price or reject it.
                    </p>
                  </AccordionBody>
                </AccordionItem>
                <AccordionItem index={4}>
                  <AccordionHead>
                    <AccordionIcon icons={['plus', 'minus']} />
                    <h6 className={'h6'}>
                      What are the guarantees that my money is safe?
                    </h6>
                  </AccordionHead>
                  <AccordionBody>
                    <p className={'p'}>
                      Once the request is received and the price is
                      agreed with you, we send you a payment link to the
                      invoice. You can submit the payment with any
                      Credit/Debit card. When following the link you are
                      automatically taken to 128-bit encrypted HTTPS
                      payment page. You can initiate a transaction
                      directly from your phone. Nerdify has no access to
                      your personal information or your CC data.
                    </p>
                  </AccordionBody>
                </AccordionItem>
              </AccordionItemHolder>
            </Accordion>
          </Holder>
        </Faq2>

        <Footer2 />
      </div>
    </LocalesPage>
  );
};

export default ThankYou;
