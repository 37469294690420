import React from "react";
import Text from "../../../../base_components/Text";
import { Link } from 'react-router-dom'

export const FAQItems = [
  {
    Q: "Who are the Nerds?",
    A: <>Our Nerds are experienced freelancers with expertise in a wide range of subjects. They are available 24/7 on our platform to assist you with almost any request. Every Nerd prior to joining our platform agreed to follow our Honor Code and only provide services that do not violate any academic, college, university or corporate policies.</>
  },
  {
    Q: "What tasks can Nerds help me with?",
    A: <>They can help you out with almost anything! The Nerds help with any type of task except for those that violate academic, college, university or corporate policies. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How do you select the right Nerd for my request?",
    A: <>The platform algorithm selects the best-fit Nerd to be assigned to your request by comparing different parameters: specialty, experience, rating, availability, additional skills, etc.</>
  },
  {
    Q: "How quickly can I get a Nerd to help?",
    A: <>You can contact us 24/7 to get a Nerd to work with you asap! However, we kindly ask you to keep in mind that you should submit your help request no less than 3 hours before the due time. This is the only way we can be sure that the Nerd has enough time to prepare to work with you and clarify all the details.</>
  },
  {
    Q: "How much should I pay for using Nerdify?",
    A: <>Getting in touch with us is free. We only charge an extra fee (11%) in addition to the cheapest possible rate of the Nerd assigned to work with you. You'll always agree to the final cost before proceeding with the request! It's up to you whether to accept the price or reject it.</>
  },
  {
    Q: "What are the guarantees that my money is safe?",
    A: <>Once the request is received and the price is agreed with you, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to a 128-bit encrypted HTTPS payment page. You can initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data!</>
  },
];

export const FAQItems2 = [
  {
    Q: "Who are the Nerds?",
    A: <>Our Nerds are experienced freelancers with expertise in a wide range of subjects. They are available 24/7 on our platform to assist you with almost any request. Every Nerd prior to joining our platform agreed to follow our Honor Code and only provide services that do not violate any academic, college, university or corporate policies.</>
  },
  {
    Q: "Can Nerds help with homework?",
    A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your homework, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
  },
  {
    Q: "How do you select the right Nerd for my request?",
    A: <>The platform algorithm selects the best-fit Nerd to be assigned to your request by comparing different parameters: specialty, experience, rating, availability, additional skills, etc.</>
  },
  {
    Q: "How quickly can I get a Nerd to help?",
    A: <>You can contact us 24/7 to get a Nerd to work with you asap! However, we kindly ask you to keep in mind that you should submit your help request no less than 3 hours before the due time. This is the only way we can be sure that the Nerd has enough time to prepare to work with you and clarify all the details.</>
  },
  {
    Q: "How much should I pay for using Nerdify?",
    A: <>Getting in touch with us is free. We only charge an extra fee (11%) in addition to the cheapest possible rate of the Nerd assigned to work with you. You'll always agree to the final cost before proceeding with the request! It's up to you whether to accept the price or reject it.</>
  },
  {
    Q: "What are the guarantees that my money is safe?",
    A: <>Once the request is received and the price is agreed with you, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to a 128-bit encrypted HTTPS payment page. You can initiate a transaction directly from your phone. Nerdify has no access to your personal information or your CC data!</>
  },
];

export const FAQItems3 = [
  {
    Q: "Who are the Nerds?",
    A: <Text>Nerds are pre-screened freelancers with a deep expertise in a wide range of domains. They are available 24/7 on our platform to assist you with almost any request. Each Nerd agreed to follow our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and always comply with institution and company policies of the client.</Text>
  },
  {
    Q: "How quickly can I get help from a Nerd?",
    A: <Text>Feel free to contact us at any time to get a Nerd assigned to your task! However, we ask you to submit your help request no less than 3 hours prior to due time. This way we can be sure the Nerd assigned has enough time to clarify all the details and complete your task.</Text>
  },
  {
    Q: "What requests can a Nerd help me with?",
    A: <Text>Our Nerds can help you with any type of task except for those that are illegal or violate university and corporate policies.</Text>
  },
  {
    Q: "How much should I pay for your service?",
    A: <Text>Getting in touch with Nerdify is always free. We only charge a small extra fee (11%) in addition to the cheapest possible rate for products or services that you request. We always agree the final cost before any charges are made! Thus, there is nothing to worry about — it’s up to you whether to accept the price or reject it.</Text>
  },
  {
    Q: "How can I be sure my money is secure?",
    A: <Text>One the request is made, we send you a payment link to the invoice. You can submit the payment with any Credit/Debit card. When following the link you are automatically taken to 128-bit encrypted HTTPS payment page. You can type your credit card information and initiate a transaction directly from your phone. Nerdify has no access to your personal information, as well as to your CC data.</Text>
  },
  {
    Q: "How do you find the Nerds for your platform?",
    A: <Text>The platform algorithm picks the best-matching Nerd to be assigned to your task by comparing various parameters: specialty, experience, rating, availability, additional skills, etc.</Text>
  },
];
