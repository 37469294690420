import React from 'react';
import { NavLink } from 'react-router-dom';

import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';

import { REACT_APP_PP_EMAIL } from '../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Privacy Policy</h1>

        <div className="textPage-item">
          <h2 className="h3">
            1. Introduction to thenerdify.com's Privacy Policy
          </h2>
          <p className="p">
            Our privacy policy tells you how we collect, store, process and use data received from you on our website. We can update our privacy policy occasionally because we understand that privacy is important in guaranteeing a safe and comfortable user experience. This policy will be enforced starting on October 15, 2021 and will apply to any use of thenerdify.com's website, mobile application and services. Please read the document to understand our views and practices regarding your personal data. At the same time Privacy Policy does not apply to any personal data that you share with the third parties.<br />
            Last updated: January 24, 2025
          </p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">2. Thenerdify.com's Information Collection</h2>
          <p className="p">
            We collect and store your phone number, as it is important for providing our services. Declining to share this information may not grant you access to our services. However, you can still check our website.
          </p>
          <p className="h4">2.1. Types of Data We Collect</p>
          <p className="p">The data we collect, store, process, and use can be divided into three subtypes: the data you decide to provide to us directly, the information we collect automatically, and the information we receive from other sources.</p>
          <p className="p">2.1.1. The information you directly shared</p>
          <p className="p">
            When you log in to our website or contact us directly through any chosen way of communication you will get to share some of your personal information such as phone number, email, degree, photos and any other information you decide to share with us.
          </p>
          <p className="p">2.1.2. Information we automatically collect</p>
          <p className="p">
            When you use thenerdify.com website or mobile application, the following information is collected automatically:
          </p>
          <ul className="ul">
            <li>Data about your device you use to access the website/application, such as device hardware model, operating system version, and screen resolution;</li>
            <li>Your domain name and address, IP address, browser type, the configuration of the app, time, date, the visit length, frequency of your visits to the website/application, and the pages you viewed. The application may request access to data that is only relevant for the core functionality of the app and only collects and uses data that is required to process the specific help request.</li>
          </ul>
          <p className="p">These are collected through our cookies, pixels, and other tracking devices. To learn more, please consult our <NavLink className="a" to="/cookies">Cookie Policy</NavLink></p>

          <p className="p">2.1.3. Information from third parties</p>
          <p className="p">We may collect information about you from the third-party services. Social media sites are typical examples of third parties. You may consent to share some of the information from your social media account to thenerdify.com. It is vital for providing our free and paid services to you.</p>

          <p className="p">2.1.4. Sensitive Information</p>
          <p className="p">We do not gather any sensitive information about you. The following should be shared at your own risk:</p>
          <ul className="ul">
            <li>Political alignment/philosophy.</li>
            <li>Religious beliefs/groups.</li>
            <li>Gender.</li>
            <li>Sexuality.</li>
            <li>Health conditions.</li>
            <li>Race and ethnicity.</li>
            <li>Etc.</li>
          </ul>
          <p className="p">If you decide to give this information to other website users, thenerdify.com does not take any responsibility for it.</p>

          <p className="h4">2.2. How does the Company Protect a Minor's Personal Information?</p>
          <p className="p">A minor’s personal information is not collected and saved by our domains. In fact, our website should only be used by individuals aged 18+.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">3. How We Use Your Information</h2>
          <p className="p">We collect your information when you visit or register on our website/application. This information is used to personalize your experience, improve our website/application, and provide you with our virtual services.</p>

          <p className="p">We use the information about you to improve, operate and protect our platform, to communicate with you and provide you with our paid and free services.</p>

          <p className="h4">3.1. Using Your Information for the Website’s/Application's Operation</p>
          <p className="p">Includes the following:</p>

          <ul className="ul">
            <li>Information is used to improve the performance of thenerdify.com’s website/application.</li>
            <li>Your data is collected and used to process your transactions and performance of other administrative tasks.</li>
            <li>Conduct statistical analyses of your activity to ensure best experience for all website/application users.</li>
            <li>A customer’s data is used to develop new products and services and monitor their behavior online.</li>
            <li>It is to investigate cases of fraud and prevent our users from violating our policies, protection of the rights and safety of all website/application users.</li>
            <li>Prevention of the website abuse and activities that are illegal or violate our Terms of Use, Privacy Policy, and other policies.</li>
          </ul>

          <p className="h4">3.2. Using Your Information for Communication Purposes</p>
          <ul className="ul">
            <li>To provide you assistance, service and support;</li>
            <li>To answer any questions, you have about the platform;</li>
            <li>To send all the latest news and updates;</li>
            <li>To conduct and organize surveys.</li>
          </ul>

          <p className="h4">3.3. Using Your Information for Advertising and Other Purposes</p>
          <ul className="ul">
            <li>To provide personalized content and features to our Users;</li>
            <li>To provide personalized advertising through third-party platforms;</li>
            <li>To promote content that may or may not interest you.</li>
          </ul>

          <p className="h4">3.4. Using Your Information for Legal Grounds</p>
          <p className="p">All the information we collect (or even that you shared) is used legally in order to improve our customers’ user experience.</p>
          <ul className="ul">
            <li>Your phone numbers are collected, stored and used to access and use our free and paid services;</li>
            <li>Your information is needed to comply with legal requirments such as, for example, to prevent cases of fraud, keep our database safe, and protect our Users’ privacy and interests.</li>
          </ul>

        </div>
        <div className="textPage-item">
          <h2 className="h3">4. Sharing Your Information and Affiliate Disclosure</h2>
          <p className="p">We have the right to share your information with third parties and local authorities in compliance with the law and to protect our legal rights. Data obtained through the short code program will not be shared with any third-parties for their marketing reasons/purposes.</p>

          <p className="h4">4.1. Service Providers</p>
          <p className="p">Third-party services are used for website and mobile application improvement and effective performance. Our third party partners may collect your personal data in compliance with the law and avail their services (Ex: payment and financial transactions, emails, messages, hosts, advertising across Internet, technical support).</p>

          <p className="h4">4.2. Other Countries</p>
          <p className="p">We may have to send your information to other countries as these countries may have data protection regulation laws that vary from your home country. Data protection measures that apply to such transfers have been taken to provide the correspondence with the requirements of this Privacy Policy.</p>

          <p className="h4">4.3. Security</p>
          <p className="p">Your information is stored securely and it is protected against illegal usage. Thenerdify.com’s employees are trained in handling your information. Only they can access your information, not anyone else. We screen our software and hardware for technical problems that may arise from security breaches.</p>

          <p className="h4">4.4. Affiliate Disclosure</p>
          <p className="p">Some links in our email campaign (or others) may be affiliate links. Affiliate links are developed to provide a means for other sites to earn advertising fees. The final cost will be the same whether you use the affiliate link. But as a member of an affiliate program, thenerdifyit.com will earn a commission if you purchase any service through an affiliate link.</p>

          <p className="h4">4.5. Third-party Partners and Privacy</p>
          <p className="p">In order to display ads online, to get information regarding the Service usage and the effectiveness of our advertisements and promotions, Thenerdify.com collaborates with third-party partners such as online advertising platforms and analytics providers. These third-parties might implement and use their own tracking tools, such as cookies and web tags, on your devices, as well as to gather or have access to Device ID/Information and other data about you, some of which might qualify as personal data according to specific state regulations. Some of these third-parties can accumulate personal data over time during your engagement with the Service or other online platforms. We might disclose information to third-party partners, typically in an aggregated, depersonalized, or pseudonymized form, along with Device ID/Information and location details, with the intention of presenting you with more relevant advertisements both on and off Service.</p>

          <p className="p">If you would like to opt-out of third-party advertising platforms and analytics providers, you have several options for that:</p>
          <ul className="ul">
            <li>There are technical specification in your browser settings that you can use to automatically inform websites of your privacy preferences. Learn more about and set up <a className="a" href="https://globalprivacycontrol.org/#about" rel="noopener noreferrer">Global Privacy Controls here</a>.</li>
            <li>We use Google Analytics, which use cookies and similar technologies to collect and analyze information about the use of the Services. Learn about <a className="a" href="https://policies.google.com/technologies/partner-sites" rel="noopener noreferrer">Google's practices</a>, and opt out of them by downloading the <a className="a" href="https://tools.google.com/dlpage/gaoptout" rel="noopener noreferrer">Google Analytics opt-out browser add-on</a>.</li>
            <li>There are settings that request that a web application (web browser) disable its tracking of an individual user. Learn more about and set up <a className="a" href="https://allaboutdnt.com/" rel="noopener noreferrer">Do Not Track here</a>.</li>
            <li>There are mobile device settings that allow you to disable tracking advertising and/or analytics. Also there is the ability for mobile users to register at <a className="a" href="https://youradchoices.com/appchoices" rel="noopener noreferrer">AppChoices</a> for their intent not to receive targeted advertising.</li>
          </ul>

          <p className="p">Kindly be aware that utilizing these options to opt out does not exempt you from receiving advertisements. You will still receive generic ads during your online activities.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">5. What are Your Rights with Regard to Your Information?</h2>
          <p className="p">Your rights are to:</p>

          <ul className="ul">
            <li>Check, modify, delete or restrict to process your information;</li>
            <li>Review and transfer all the information you shared on the platform/application;</li>
            <li>Back out of marketing emails, cookies usage and other tracking. Please review our <NavLink className="a" to="/cookies">Cookie Policy</NavLink> to learn more about cookies and our tracking technologies.</li>
            <li>Write a complaint to your local Data Protection Authority (DPA) in the company’s use and storage of your personal information.</li>
          </ul>

          <p className="p">Do you want us to delete your data or change permissions or review all the information we stored? Feel free to send us a message at <a className="a" href={`mailto:${REACT_APP_PP_EMAIL}`} rel="noopener noreferrer">{REACT_APP_PP_EMAIL}</a>.</p>
        </div>

      </Holder>
    </TextPage>
  </LocalesPage>
);
