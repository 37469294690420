import Landing from './pages/Landing';
import Landing3a from './pages/Landing3a';
import LandingStudents from './pages/LandingStudents';
import LandingBusinesses from './pages/LandingBusinesses';
import LandingAcademia from './pages/LandingAcademia';
import Cookies from './pages/Cookies';
import Refunds from './pages/Refunds';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import HowItWorks from "./pages/HowItWorks";
import Maintenance from './pages/Maintenance';
import NotFound from './pages/NotFound';
import BecomeANerd from './pages/BecomeANerd';
import AboutUs from './pages/AboutUs';
import HonorCode from './pages/HonorCode';
import TopNerds from './pages/TopNerds';
import ReportAbuse from './pages/ReportAbuse';
import ThankYou from "./pages/ThankYou";

import './../../themes/nerdify/assets/css/index.css';

import { IS_PROD } from '../../config';
import {Link} from "react-router-dom";
import React from "react";

//export const fonts = 'https://fonts.googleapis.com/css?family=Aleo:400,700|Roboto:300,300i,400,700&display=swap';
export const viewport = 'width=device-width';

export const favicons = [
  {
    type: 'image/png',
    sizes: false,
    href: '/favicon.png',
  },
];

export const routes = [
  {
    path: '/',
    meta: {
      title:
        'Nerdify | Top Assistants for Students, Colleges and Companies.',
      description:
        'Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.',
      keywords:
        'Nerdify, on-demand tutoring help, on-demand research, data collection, find a tutor, tutoring nerds, consultants, research for companies, business plan, presentation advise, research coach, research mentor,learning help, help with an interview, study help, college tutoring, university tutoring, mentors, English tutor, Math tutor, Statistics tuotr, History tutor',
      robots: 'noindex, nofollow',
    },
    component: Landing,
    exact: true,
    pageCfg: {
      headline: 'Need help with task overload?',
      subtitle: 'Get connected to Nerds!',
      hasNav: true,
      //defaultLocale: 'ca',
    },
  },
  {
    path: '/become',
    meta: {
      title: 'Nerdify | Become a Nerd',
      description:
        'Apply to become a freelance tutor. Earn money by sharing your knowledge!',
      keywords:
        'become an online tutor, become a tutor, become a nerd, online tutoring jobs, online english tutoring jobs, online college tutoring jobs, online tutoring jobs part time, how to become an online teacher, how to teach english online, online tutoring positions, freelance tutoring jobs',
      robots: 'noindex, nofollow',
    },
    component: BecomeANerd,
    pageCfg: {
    }
  },
  {
    path: '/about-us',
    meta: {
      title: 'Nerdify | About us',
      description: 'Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: AboutUs,
    pageCfg: {},
  },
  {
    path: '/cookies',
    meta: {
      title: 'Nerdify | Cookie Policy',
      description: 'Nerdify | Check out our Cookie Policy',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Cookies,
    pageCfg: {
    }
  },
  {
    path: '/refunds',
    meta: {
      title: 'Nerdify | Refund Policy',
      description: 'Nerdify | Check out our Refund Policy',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Refunds,
    pageCfg: {
    }
  },
  {
    path: '/terms-and-conditions',
    meta: {
      title: 'Nerdify | Terms of Use',
      description: 'Nerdify | Check out our Terms of Use',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Terms,
    pageCfg: {
    }
  },
  {
    path: '/privacy-policy',
    meta: {
      title: 'Nerdify | Privacy Policy',
      description: 'Nerdify | Check out our Privacy Policy',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Privacy,
    pageCfg: {
    }
  },
  {
    path: '/how-it-works',
    meta: {
      title: 'Nerdify | How it works',
      description: 'Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HowItWorks,
    exact: true,
    pageCfg: {},
  },
  {
    path: '/honor-code',
    meta: {
      title: 'Nerdify | Honor Code',
      description: 'Nerdify | Check out our Honor Code',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: HonorCode,
    exact: true,
    pageCfg: {
    }
  },
  {
    path: '/report-abuse',
    meta: {
      title: 'Nerdify | Report abuse',
      description: 'Nerdify | Report Abuse',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: ReportAbuse,
    exact: true,
    pageCfg: {
    }
  },
  {
    path: '/top-nerds',
    meta: {
      title: 'Nerdify | Top Nerds',
      description: 'Nerdify | Top Nerds',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: TopNerds,
    exact: true,
    pageCfg: {
    }
  },

  {
    path: '/college-students-1',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Help with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'essays, writing tips, essay tutoring, essay tutor, university essay, best essay examples, outline, my essay, title help, essay papers',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_1',
      faq: [
        {},
        {
          Q: "Can Nerds help with an essay?",
          A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your essays, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
        },
      ],
      ...(IS_PROD && {
        defaultPhone: {
          ca: '+1 778 722 0444',
        },
      }),
    },
  },
  {
    path: '/college-students-2',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Help with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'papers, research paper, my paper, writing tips, research paper tutoring, research paper tutors, college papers, research, writing a paper, paper tutor, write papers, outline, title',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_2',
      faq: [
        {},
        {
          Q: "Can Nerds help with a paper?",
          A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your papers, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
        },
      ],
    },
  },
  {
    path: '/college-students-3',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Help with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'hw, homework, tutor help, my homework, homework management, homework tutoring, homework tutor, hw tutors, your homework, homework tips',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_3',
      faq: [
        {},
        {
          Q: "Can Nerds help with homework?",
          A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your homework, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
        },
      ],
    },
  },
  {
    path: '/college-students-4',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Help with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'assignment, my assignment, tutoring help, assignment tutor, university assignment, writing assignment examples, college assignment, writing assignments tips, assignment consultation',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_4',
      faq: [
        {},
        {
          Q: "Can Nerds help with assignment?",
          A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your assignment, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
        },
      ],
    },
  },
  {
    path: '/college-students-5',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Help with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'writing, writing tips, thesis writing, how to become a better writer, research writing, writing a book review, report writing, literature review, outline, college writing, university writing, writing tutor',
      robots: 'noindex, nofollow',
    },
    component: Landing3a,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'college_students_5',
      faq: [
        {},
        {
          Q: "Can Nerds help with a writing task?",
          A: <>You can ask for help with any type of task but the Nerds do not help with requests that violate any academic, college, university or corporate policies. The Nerds will refuse to do your writing task, but they can find you the materials, help understand a difficult topic, advise you on how to structure your research, and the like. Please read our <Link className="link a__underline" to="/honor-code">Honor Code</Link> and <Link className="link a__underline" to="/honor-code#prohibited-tasks">the list</Link> of prohibited tasks to learn more.</>
        },
      ],
    },
  },
  {
    path: '/businesses',
    meta: {
      title: 'Nerdify | Top Assistants for Students, Colleges and Companies.',
      description:
        'Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.',
      keywords:
        'Nerdify, on-demand tutoring help, on-demand research, data collection, find a tutor, tutoring nerds, consultants, research for companies, business plan, presentation advise, research coach, research mentor,learning help, help with an interview, study help, college tutoring, university tutoring, mentors, English tutor, Math tutor, Statistics tuotr, History tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingBusinesses,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'businesses',
    },
  },
  {
    path: '/students',
    meta: {
      title: 'Nerdify | Top Study Assistants for University Students',
      description:
        '1000s of Top Nerds Right at Your Fingertips. Get On-demand Assistance with Study, Business & Academia Within Minutes. Text Now.',
      keywords:
        'Nerdify, on-demand tutoring help, on-demand research, data collection, find a tutor, tutoring nerds, consultants, research for companies, business plan, learning help, help with an interview, study help, college tutoring, university tutoring, mentors, English tutor, Math tutor, Statistics tuotr, History tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingStudents,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'students',
    },
  },
  {
    path: '/academia',
    meta: {
      title: 'Nerdify | Top Assistants for Students, Colleges and Companies.',
      description:
        'Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.',
      keywords:
        'Nerdify, on-demand tutoring help, on-demand research, data collection, find a tutor, tutoring nerds, consultants, research for companies, business plan, presentation advise, research coach, research mentor,learning help, help with an interview, study help, college tutoring, university tutoring, mentors, English tutor, Math tutor, Statistics tuotr, History tutor',
      robots: 'noindex, nofollow',
    },
    component: LandingAcademia,
    exact: true,
    pageCfg: {
      hasNav: true,
      demoCards: 'academia',
    },
  },
  {
    path: '/maintenance',
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: Maintenance,
    pageCfg: {
    }
  },

  {
    path: '/thank-you',
    meta: {
      title: 'Nerdify | #1 Personal Assistant for College Students',
      description:
        'Meet your deadlines with Nerds. Text your task via SMS and get instant help from Top 2% Nerds. Try Now! Available on the go.',
      keywords:
        'personal assistant for students, someone to edit my paper, proofread my essay, homework help from online tutor, online assignment tutoring, need help writing, concierge service for students, writing tips, nerds online writing help, help to write my paper, pay someone, title help, outline help, essay papers, essay writing tutor, my homework, homework help online, my assignment, college writing, university writing, need help writing, master thesis proofreading, paper checker, essay revision, formatting, need help writing, essay tutoring, college paper, homework tutoring, help with assignments, editing, proofreading',
      robots: 'noindex, nofollow',
    },
    component: ThankYou,
    exact: true,
    pageCfg: {
      backLink: true,
    },
  },

  {
    meta: {
      title: '',
      description: '',
      keywords: '',
      robots: 'noindex, nofollow',
    },
    component: NotFound,
    pageCfg: {
    }
  },
];
