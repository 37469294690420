import React, {Component, Fragment} from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import {Footer as FooterTheme, FooterType2} from '../../../../themes/nerdify/components/Footer/Footer';
import {REACT_APP_ADDRESS_US, REACT_APP_ADDRESS_EU, REACT_APP_CONTACT_EMAIL} from "../../../../config";
//import Dropdown from "../../../../themes/nerdify/components/Dropdown";
//import { Footer as FooterTheme, FooterIcon } from '../../../../themes/nerdify/components/Footer/Footer';

export const FooterNavList = [
  { name: 'Home', link: '/' },
  { name: 'For Businesses', link: '/businesses' },
  { name: 'For Students', link: '/students' },
  { name: 'For Academia', link: '/academia' },

  { name: 'How it works', link: '/how-it-works'},
  { name: 'TOP Nerds', link: '/top-nerds' },
  { name: 'Become a Nerd', link: '/become' },
  { name: 'About Us', link: '/about-us', breakDesktop: true },

  { name: 'Terms of Use', link: '/terms-and-conditions' },
  { name: 'Privacy Policy', link: '/privacy-policy' },
  { name: 'Cookie Policy', link: '/cookies', breakDesktop: true },

  { name: 'Refund Policy', link: '/refunds' },
  { name: 'Honor Code', link: '/honor-code' },
  { name: 'Report Abuse', link: '/report-abuse' },
];

class FooterNavContent extends Component {
  render () {
    return (
      <nav className='footer-nav'>  {/*.type2 = 3 columns*/}
        {FooterNavList.map((item, key) => {
          return(
            <NavLink
              className={classnames('footer-nav-link', {'break-after-desktop': item.breakDesktop})} /*'break-after-mobile': item.breakMobile*/
              to={item.link} key={`fn${key}`}>{item.name}</NavLink>
          )
        })}
      </nav>
    )
  }
}

export default (props) => {
  return (
    <FooterTheme {...props} footerLogo="footer-logo-au" content2="By accessing this website you agree to our Terms&Conditions, Privacy and Cookie Policies." copyright="&copy; 2020 thenerdify.com">
      <FooterNavContent />
    </FooterTheme>
  )
};

export const Footer2 = (props) => {
  return (
    <FooterType2 {...props} footerLogo="footer-logo-thenerdify-invert" copyright="&copy; 2025 thenerdify.com">
      <Fragment>
        <p className="footer-content1">Nerdify. Made with love.</p>
        <p className="footer-content2">By accessing this website you agree to our Terms of Use, Privacy and Cookie Policies.</p>

        <FooterNavContent />

        <div className="bottom-part">
          <address className="footer-address">
            <div className="bottom-title">Our Addresses:</div>
            {REACT_APP_ADDRESS_US && <div className="footer-address-line"><span className="footer-address-title">Our US address: </span>{REACT_APP_ADDRESS_US}</div>}
            {REACT_APP_ADDRESS_EU && <div className="footer-address-line"><span className="footer-address-title">Our EU address: </span>{REACT_APP_ADDRESS_EU}</div>}
          </address>
          <div className="footer-contacts">
            <div className="bottom-title">Contact Us:</div>
            <a className='footer-content-link' href={"mailto:" + REACT_APP_CONTACT_EMAIL}>{REACT_APP_CONTACT_EMAIL}</a>
          </div>
        </div>

      </Fragment>
    </FooterType2>
  )
};
