import React from 'react';

export const slider1Items = [
  {
    title: 'Guidance',
    msg: 'I need advice on choosing the best data analysis method for my project.',
  },
  {
    title: 'Application help',
    msg: "Looking for tips to improve my application letter and grab the employer's attention!",
  },
  {
    title: 'Advice',
    msg: 'I wrote a summary of a research article but I need an advice on how to structure it correctly. Can you help with that?',
  },
  {
    title: 'Tutor in Economics',
    msg: 'I am falling behind in my economics class, please find me a good tutor!',
  },

  {
    title: 'Grammar',
    msg: 'Need a tutor to work on my language and punctuation so that I could improve my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need an advice on my PowerPoint for a TED talk on Philosophy edu by Wednesday. Can u help?',
  },
  {
    title: 'Market Research',
    msg: 'Please help me put together my SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to share writing tips so that I could finish my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </>
    ),
  },
  {
    title: 'Find materials',
    msg: "I need a list of recently published articles on findings in Social Psychology.",
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <>
        Need help with Accounting: a tutor to go through my calculations with me to make sure they're correct.
      </>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg:
      'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please, clarify the concept of Manifest Function so that I could understand my assignment in Sociology.',
  },
  {
    title: 'English tutor',
    msg: 'I am almost done writing my research paper and need someone to check if there are no grammar errors as English is not my native language.',
  },
  {
    title: 'Application form',
    msg: 'I need recommendations on filling out an exchange program application form.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost done, but I am still struggling with sources. Can you recommend some?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg:
      'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'English',
    msg: 'I want your best Nerd to help me with my English paper. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg:
      'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can someone help me with that?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </>
    ),
  },
  {
    title: 'Speech',
    msg:
      'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

/* /businesses LandingBusinesses */
export const slider2Items = [
  {
    title: 'Email marketing',
    msg: 'Do you have someone who understands how to collect insights from existing email campaigns?',
  },
  {
    title: 'Survey help',
    msg: 'I need help creating questions for the online survey by tomorrow!',
  },
  {
    title: 'Data analysis',
    msg: 'Please find me a specialist who can help analyze big loads of data for my report.',
  },
  {
    title: 'Product-market fit',
    msg: 'Can someone help me with an assessment of product-market fit in my industry?',
  },
].concat(slider1Items);

/* /us-companies LandingUsBusinesses */
export const slider2aItems = [
  {
    title: 'Guidance',
    msg: 'I need advice on choosing the best data analysis method for my project.',
  },
  {
    title: 'Application help',
    msg: "Looking for tips to improve my application letter and grab the employer's attention!",
  },
  {
    title: 'Advice',
    msg: 'I wrote a summary of a research article but I need an advice on how to structure it correctly. Can you help with that?',
  },
  {
    title: 'Tutor in Economics',
    msg: 'I am falling behind in my economics class, please find me a good tutor!',
  },

  {
    title: 'Grammar',
    msg: 'Need a tutor to work on my language and punctuation so that I could improve my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need an advice on my PowerPoint for a TED talk on Philosophy edu by Wednesday. Can u help?',
  },
  {
    title: 'Market Research',
    msg: 'Please help me put together my SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to share writing tips so that I could finish my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </>
    ),
  },
  {
    title: 'Find publications',
    msg: "I need a list of recently published articles on findings in social marketing.",
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <>
        Need help with Accounting: a tutor to go through my calculations with me to make sure they're correct.
      </>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please, clarify the concept of Manifest Function so that I could understand my assignment in Sociology.',
  },
  {
    title: 'Formatting',
    msg: 'I am almost done writing my research and need someone to check if I formatted it properly.',
  },
  {
    title: 'Application form',
    msg: 'I need recommendations on filling out an exchange program application form.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: (
      <>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost done, but I am still struggling with sources. Can you recommend some?',
  },
  {
    title: 'Job Interview',
    msg: 'Do you have a Nerd who can prepare me for the job interview?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'English',
    msg: 'I want your best Nerd to help me with my English paper. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can someone help me with that?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },

  {
    title: 'Email marketing',
    msg: 'Do you have someone who understands how to collect insights from existing email campaigns?',
  },
  {
    title: 'Survey help',
    msg: 'I need help creating questions for the online survey by tomorrow!',
  },
  {
    title: 'Data analysis',
    msg: 'Please find me a specialist who can help analyze big loads of data for my report.',
  },
  {
    title: 'Product-market fit',
    msg: 'Can someone help me with an assessment of product-market fit in my industry?',
  },
];



/* /academia LandingAcademia */
export const slider3Items = [
  {
    title: 'Guidance',
    msg: 'I need advice on choosing the best data analysis method for my project.',
  },
  {
    title: 'Application help',
    msg: "Looking for tips to improve my application letter and grab the employer's attention!",
  },
  {
    title: 'Advice',
    msg: 'I wrote a summary of a research article but I need an advice on how to structure it correctly. Can you help with that?',
  },
  {
    title: 'Tutor in Economics',
    msg: 'I am falling behind in my economics class, please find me a good tutor!',
  },

  {
    title: 'Grammar',
    msg: 'Need a tutor to work on my language and punctuation so that I could improve my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need an advice on my PowerPoint for a TED talk on Philosophy edu by Wednesday. Can u help?',
  },
  {
    title: 'Market Research',
    msg: 'Please help me put together my SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to share writing tips so that I could finish my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: "I don't understand inferential statistics methods. Can u explain me the methodology?",
  },
  {
    title: 'Find materials',
    msg: "I need a list of recently published articles on findings in Social Psychology.",
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <>
        Need help with Accounting: a tutor to go through my calculations with me to make sure they're correct.
      </>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please, clarify the concept of Manifest Function so that I could understand my assignment in Sociology.',
  },
  {
    title: 'English tutor',
    msg: 'I am almost done writing my research paper and need someone to check if there are no grammar errors as English is not my native language.',
  },
  {
    title: 'Application form',
    msg: 'I need recommendations on filling out an exchange program application form.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay?',
  },
  {
    title: 'English Tutor',
    msg: "I'm very bad in writing; help me find an English tutor to improve my skills, please."
  },
  {
    title: 'Sources',
    msg: 'My homework is almost done, but I am still struggling with sources. Can you recommend some?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'English',
    msg: 'I want your best Nerd to help me with my English paper. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can someone help me with that?',
  },
  {
    title: 'Biology Tutor',
    msg: "I'm stuck with my homework and need help. Do you have an online tutor in Biology?",
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },

  {
    title: 'Analysis',
    msg: 'I need to analyze the results of a questionnaire for my research, do you have someone who can help?',
  },
  {
    title: 'Proofreading',
    msg: 'I think my scientific article needs a grammar and punctuation check. Do you have an available Nerd for that?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to create a social media campaign to collect insights for a psychological experiment?',
  },
  {
    title: 'Article selection',
    msg: 'I need a list of the latest publications on sulfone chemistry by tomorrow!',
  },
];

/* / (main landing), /students, /us-students LandingStudents */
export const slider4Items = [
  {
    title: 'Guidance',
    msg: 'I need advice on choosing the best data analysis method for my project.',
  },
  {
    title: 'Application help',
    msg: "Looking for tips to improve my application letter and grab the employer's attention!",
  },
  {
    title: 'Advice',
    msg: 'I wrote a summary of a research article but I need an advice on how to structure it correctly. Can you help with that?',
  },
  {
    title: 'Tutor in Economics',
    msg: 'I am falling behind in my economics class, please find me a good tutor!',
  },

  {
    title: 'Grammar',
    msg: 'Need a tutor to work on my language and punctuation so that I could improve my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need an advice on my PowerPoint for a TED talk on Philosophy edu by Wednesday. Can u help?',
  },
  {
    title: 'Market Research',
    msg: 'Please help me put together my SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to share writing tips so that I could finish my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: "I don't understand inferential statistics methods. Can u explain me the methodology?",
  },
  {
    title: 'Find materials',
    msg: "I need a list of recently published articles on findings in Social Psychology.",
  },
  {
    title: 'Tutor in Accounting',
    msg: "Need help with Accounting: a tutor to go through my calculations with me to make sure they're correct.",
  },
  {
    title: 'Binomial Distribution',
    msg: 'I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please, clarify the concept of Manifest Function so that I could understand my assignment in Sociology.',
  },
  {
    title: 'English tutor',
    msg: 'I am almost done writing my research paper and need someone to check if there are no grammar errors as English is not my native language.',
  },
  {
    title: 'Application form',
    msg: 'I need recommendations on filling out an exchange program application form.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: "I'm very bad in writing; help me find an English tutor to improve my skills, please.",
  },
  {
    title: 'Sources',
    msg: 'My homework is almost done, but I am still struggling with sources. Can you recommend some?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'English',
    msg: 'I want your best Nerd to help me with my English paper. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can someone help me with that?',
  },
  {
    title: 'Biology Tutor',
    msg: "I'm stuck with my homework and need help. Do you have an online tutor in Biology?",
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];

/*  /college-students-1..5, /us-college-students-1..5 Landing3 */
export const slider5Items = [
  {
    title: 'Guidance',
    msg: 'I need advice on choosing the best data analysis method for my project.',
  },
  {
    title: 'Application help',
    msg: "Looking for tips to improve my application letter and grab the employer's attention!",
  },
  {
    title: 'Advice',
    msg: 'I wrote a summary of a research article but I need an advice on how to structure it correctly. Can you help with that?',
  },
  {
    title: 'Tutor in Economics',
    msg: 'I am falling behind in my economics class, please find me a good tutor!',
  },

  {
    title: 'Grammar',
    msg: 'Need a tutor to work on my language and punctuation so that I could improve my Sociology class!',
  },
  {
    title: 'Presentation',
    msg: 'Need an advice on my PowerPoint for a TED talk on Philosophy edu by Wednesday. Can u help?',
  },
  {
    title: 'Market Research',
    msg: 'Please help me put together my SWOT analysis for my product.',
  },
  {
    title: 'Tutoring',
    msg: 'Can someone explain to me the concept of confidence interval today?',
  },
  {
    title: 'Internship',
    msg: 'I want someone to share writing tips so that I could finish my motivation letter.',
  },
  {
    title: 'Tutor in Statistics',
    msg: (
      <>
        Can't do homework because I don't understand inferential statistics
        methods. Can u explain me the methodology?
      </>
    ),
  },
  {
    title: 'Find materials',
    msg: "I need a list of recently published articles on findings in Social Psychology.",
  },
  {
    title: 'Tutor in Accounting',
    msg: (
      <>
        Need help with Accounting: a tutor to go through my calculations with me to make sure they're correct.
      </>
    ),
  },
  {
    title: 'Binomial Distribution',
    msg: 'I need to understand Binomial Distribution. Can you explain it in simple words?',
  },
  {
    title: 'CV editing',
    msg: 'I wanna shift to a new company and want someone to help me update my CV.',
  },
  {
    title: 'Tutor in Sociology',
    msg: 'Please, clarify the concept of Manifest Function so that I could understand my assignment in Sociology.',
  },
  {
    title: 'English tutor',
    msg: 'I am almost done writing my research paper and need someone to check if there are no grammar errors as English is not my native language.',
  },
  {
    title: 'Application form',
    msg: 'I need recommendations on filling out an exchange program application form.',
  },
  {
    title: 'Bibliographic citations',
    msg: 'Can you recommend the right sources that I should read for my essay in English literature?',
  },
  {
    title: 'English Tutor',
    msg: (
      <>
        I'm very bad in writing; help me find an English tutor to improve my
        skills, please.
      </>
    ),
  },
  {
    title: 'Sources',
    msg: 'My homework is almost done, but I am still struggling with sources. Can you recommend some?',
  },
  {
    title: 'Consultation',
    msg: 'Can someone advise me on how to write a Chemistry lab report?',
  },
  {
    title: 'Tutor in Healthcare',
    msg: 'I need a tutor to give me recommendations of good sources, so I can start writing a paper in Nanobiology.',
  },
  {
    title: 'English',
    msg: 'I want your best Nerd to help me with my English paper. This task is very important for my class!',
  },
  {
    title: 'Business research',
    msg: 'Can you help with a research of the biggest Pulp and Paper Manufacturers in USA?',
  },
  {
    title: 'Audio transcription',
    msg: 'I need help writing down marketing conference notes from the audio file. Can someone help me with that?',
  },
  {
    title: 'Biology Tutor',
    msg: (
      <>
        I'm stuck with my homework and need help. Do you have an online tutor in
        Biology?
      </>
    ),
  },
  {
    title: 'Speech',
    msg: 'I need help writing a speech for a company-wide meeting in an easy-going manner.',
  },
];


