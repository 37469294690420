import React from 'react';
import Holder from './../../../../themes/nerdify/components/Holder';
import TextPage from './../TextPage';
import LocalesPage from './../LocalesPage';
import {NavLink} from "react-router-dom";

import { REACT_APP_CONTACT_EMAIL } from '../../../../config';

export default (props) => (
  <LocalesPage {...props}>
    <TextPage au>
      <Holder className="holder_1">
        <h1 className="h1">Terms of Use</h1>

        <div className="textPage-item">
          <p className="p">Acceptance of Terms, Modifications. We refer to our Users as “you.” You can refer to thenerdify.com as “our,” “us,” ""platform"" and “we.” These terms of use are a binding legal agreement between you and thenerdify.com website. Every user is required to read the terms and conditions presented below. The terms govern your use of the our online and/or mobile services and our applications, including but not limited to, our website and mobile apps, our social media applications, if any, or any other access point we make available to you, including any content, functionality, and services offered on our platform. Whether agreeing to these terms during the account sign-up process or starting using our platform with or without creating an account as a guest, you accept these terms, our <NavLink className="a" to="/privacy-policy">Privacy Policy</NavLink> and <NavLink className="a" to="/cookies">Cookie Policy</NavLink>, incorporated herein by reference. Our platform is offered and available to users who are 18 years of age or older. By using our platform, you represent and warrant that you are of legal age to form a binding contract with us and meet all of the foregoing eligibility requirements. If you do not meet all of these requirements, you must not access or use the website.</p>
          <p className="p">If you feel that this document contradicts your personal beliefs, attitudes or views, then you are prohibited from using our service. All Users and visitors are requested to interact with Nerdify Platform in strict compliance with the conditions below and your respective laws.</p>
          <p className="p">We may revise and update these Terms from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of our platform thereafter. Your continued use of the Nerdify Platform following the posting of revised Terms means that you accept and agree to the changes.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">About us</h2>
          <p className="p">Thenerdify.com is an online platform that caters to the needs of our Users. We refer them to our freelancers for their needs. They serve as their personal assistants. We consider our freelancers as contractual employees, hence, the we do not represent them in any way and do not provide services to the User as freelancer. We operate as a marketplace to allow Users to access freelancers to meet needs for services in connection with different tasks and dont provide services directly. Like any other freelance platform, we do not approve of anyone to maintain illegal activities on our website/application. Bear in mind that we do not exercise control and responsibility for the service that freelancers will provide. Users should exercise independent judgment when engaging with freelancers.</p>
          <p className="p">Our platform is used to locate freelancer services and to facilitate payments. You agree that we has no liability for damages associated with freelancer services or resulting from any other transactions between Users of the platform and the freelancers. All transactions and business engagements between end users and freelancers are conducted through the platform.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Contact Information Usage and Platform Messaging Program</h2>
          <ul className="ul">
            <li>To access the platform or services you may be asked to provide certain registration details or other information. Utilizing the platform may require you to register by providing your name and email address, registering by logging in to third-party social media applications or registering through a Google account. By doing so, you create your user id and password.</li>
            <li>All the information you provide on the platform is accurate, up-to-date, and complete. By registering or interacting with the platform, including through any interactive features, you acknowledge that your information is governed by our <NavLink className="a" to="/privacy-policy">Privacy Policy</NavLink> and consent to the actions we take regarding your data in accordance with that policy.</li>
            <li>Thenerdify.com uses text messaging (SMS) as a prime way of delivering our services. By requesting a text-back on our website or registering in our application you agree to receive text messages (SMS) as a part of our platform messaging program. Participating in the SMS program is optional, but please be aware that we are not able to deliver our services without using text messages (SMS).</li>
            <li>Your phone number will only be used as a tool for communication regarding your requests. You will receive the following types of messages: text-back messages, information about our services, request-related quotes, request-related status updates, answers to your questions and other types of messages that help us provide you with relevant information and services.</li>
            <li>Your phone number is safely stored on our hosting. We use Amazon Web Services, which is one of the most secure hosting providers in the world.</li>
            <li>You can cancel the SMS service at any time. If you wish to stop getting texts from us please text “STOP”. Upon receiving this text message, we will send you an SMS message to confirm that you have been unsubscribed. After this, you will no longer receive SMS messages from us.</li>
            <li>If you want to join again, just visit our website and send a request for text back, and we will start sending SMS messages to you again.</li>
            <li>If you are experiencing issues with the messaging program you can reply with the keyword HELP for more assistance.</li>
            <li>Thenerdify.com does not charge you any extra fee for chatting with us via SMS. However, operator message and data rates may apply for any messages sent to you from us and to us from you. If you have any questions about your text plan or data plan, it is best to contact your wireless provider.</li>
            <li>Carriers are not liable for delayed or undelivered messages.</li>
            <li>If you have any questions regarding privacy, please read our <NavLink className="a" to="/privacy-policy">Privacy Policy</NavLink>.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Termination</h2>
          <p className="p">You acknowledge and agree that we are not obligated to offer our platform in any particular location or region, nor to continue providing it once access has been granted. We reserve the right to suspend or terminate your access to the platform, with or without cause, at any time. You may also suspend or terminate your use of the platform at your discretion, for any reason. To deactivate your account, please contact us. Please note, any outstanding payment obligations will remain due even if your account is suspended or terminated.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Your Content</h2>

          <p className="p">The platform may include interactive features such as reviews, listings, message boards, and other where users can post content. Any content you post is non-confidential and non-proprietary. By posting, you grant us, our affiliates, freelancers, service providers, and their respective successors the right to use, modify, display, and distribute your content for any purpose.</p>

          <p className="p">You represent and warrant that:</p>
          <ul className="ul">
            <li>You own or control all rights to your content and have the right to grant this license.</li>
            <li>Your content complies with these Terms.</li>
            <li>You are responsible for the legality, accuracy, and appropriateness of your content.</li>
            <li>We are not liable for the content or accuracy of any User content posted by you or others on the platform.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Fees and payment</h2>

          <p className="p">Users purchase freelancer services via the platform by agreeing to an engagement and paying associated fees, including service and applicable taxes. Freelancers confirm engagements with Users, and the company collects payments on their behalf.</p>
          <p className="p">Cancellations by Users require 24-hour notice for a full refund.</p>
          <p className="p">Force majeure. In certain emergency situations, such as natural disasters or conflicts, where performing an agreed engagement becomes impossible or impractical, standard cancellation policies may not apply. In these cases, the company may, at its discretion, offer refunds under different terms than those outlined in the cancellation policy.</p>
          <p className="p">Failure to perform by a freelancer may lead to refund issuance. If we determine, at our discretion, that a freelancer has not fulfilled their obligations as agreed with the User or has violated the Terms, we may choose to cancel the engagement and/or provide a full or partial refund to the User.</p>
          <p className="p">Payment Disputes; Payment Outside of the platform. Payments are processed 48 hours post-engagement completion, and disputes between Users and freelancers are not mediated by the company. The platform has no responsibility or liability with respect to any tips, bonuses, or other payments made outside of the platform.</p>
          <p className="p">By providing payment for services on the platform, you agree to provide valid, up-to-date payment information and maintain it on file as long as you have pending engagements. The Company acts as a payment facilitator for freelancers. You authorize charges to your payment method, including backup methods if necessary. If payment fails due to invalid information, you understand that services may not be provided, and neither the platform nor the freelancer is responsible. All payments are non-refundable unless otherwise stated.</p>
          <p className="p">You are responsible for paying any applicable taxes related to your use of freelancer services on the platform, including sales tax on the fees you pay.</p>
        </div>


        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Content and Copyright</h2>
          <p className="p">The provided content, images, files, etc. other than those of third parties, are copyrighted by thenerdify.com. You may not copy or transfer our content, as it violates copyright law.</p>
          <p className="p">Copyright Infringement. We take copyright infringement seriously and respond to notices as outlined in the U.S. Digital Millennium Copyright Act (DMCA). If you believe that content on our latform violates your copyright, you can submit a DMCA Notice of Alleged Infringement to us at:</p>
          <p className="p">
            Subject Line: DMCA Compliance{' '}
            <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`} rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>
          </p>
          <p className="p">Please include the following information in your notice:</p>
          <ul className="ul">
            <li>A description of the copyrighted work you believe has been infringed.</li>
            <li>The URL or location of the infringing material on our platform.</li>
            <li>Your name, mailing address, phone number, and email address.</li>
            <li>A statement confirming your good faith belief that the use of the material is unauthorized.</li>
            <li>A declaration under penalty of perjury that the provided information is accurate and that you are the copyright owner or authorized to act on their behalf.</li>
            <li>An electronic or physical signature of the copyright owner or their authorized representative.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Policy on Refund</h2>
          <p className="p">You may refund your payment for the Freelancer’s service. You are eligible for a refund 60 days after your request was completed. Then, your request will be under review for five to seven business days before we finalize it. Your money will be refunded to your Nerdify balance or your bank account. Or we can also find another Freelancer for you to complete your request which might take 12 hours.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Users’ Safety, Prohibited Uses and Security</h2>

          <p className="p">You may only use the platform for lawful purposes and in compliance with these Terms. The following activities are prohibited:</p>
          <ul className="ul">
            <li>Violating Laws: Breaching any laws, including those regarding data or software exports.</li>
            <li>Harming Minors: Exploiting or attempting to harm minors by exposing them to inappropriate content or requesting personal info.</li>
            <li>Unauthorized Advertising: Sending unsolicited promotional materials, spam, or junk mail.</li>
            <li>Impersonation: Pretending to be the platform, its employees, or other users.</li>
            <li>Disruptive Behavior: Interfering with others' use of the platform or harming the platform itself.</li>
          </ul>

          <p className="p">Additionally, you agree not to:</p>
          <ul className="ul">
            <li>Disrupt Platform: Overburden, damage, or interfere with platform functionality, including real-time activities.</li>
            <li>Unauthorized Access: Use automated tools or manual processes to copy, monitor, or access platform content without permission.</li>
            <li>Malicious Software: Introduce viruses, worms, or other harmful materials.</li>
            <li>Unauthorized Access: Attempt unauthorized access to servers, databases, or systems.</li>
            <li>Platform Attacks: Engage in denial-of-service attacks or disrupt operations.</li>
          </ul>

          <p className="p">You must not:</p>
          <ul className="ul">
            <li>Collect other users' personal data.</li>
            <li>Engage in illegal activities or harm the platform, website or application and its users.</li>
            <li>Spread viruses or malware.</li>
            <li>Harass, threaten, or coerce others.</li>
            <li>Participate in illegal or destructive acts.</li>
            <li>Disable or malfunction platform services.</li>
            <li>Persuade others to commit illegal activities.</li>
          </ul>

          <p className="p">We adhere to high quality and standards of work. Therefore, we expect the same from our Users by refusing to process anyone’s requests with the following connections:</p>
          <ul className="ul">
            <li>Assisting or attempting to help another in corporate/academic dishonesty.</li>
            <li>Assisting students to cheat, or obtain grades or degrees they have not earned.</li>
            <li>Plagiarizing the work of another person without proper attribution.</li>
            <li>Impersonating someone’s identity for any purpose.</li>
            <li>Impersonation or trafficking in drugs, arms, or illegal activities.</li>
            <li>Promoting violence, hate speech, racism, sexism, or other harmful behaviors.</li>
            <li>Engaging in fraudulent activities or schemes.</li>
            <li>Distributing explicit or vulgar content.</li>
            <li>Encouraging others to violate policies or partake in harmful actions.</li>
            <li>Connecting with anyone related to the purchase of ammunition, weapons, and projectiles.</li>
            <li>Partaking in an act that encourages other users to violate the company’s policies.</li>
            <li>Engaging in matrix programs and “quick and easy money” schemes.</li>
            <li>Selling goods before the merchant has control of them.</li>
            <li>Collecting payments for the vendors/paying the vendors.</li>
            <li>Affiliating any individual with ordering money and travel cheques.</li>
            <li>Taking part in debt settlement and credit repair services.</li>
            <li>Undertaking fraudulent requests/actions.</li>
          </ul>

            <p className="p">Users must refrain from posting:</p>
          <ul className="ul">
            <li>Offensive language, violence, hate speech, racism, sexism, colorism, misandry or explicit content.</li>
            <li>Links to commercial activities, contests, or spam.</li>
            <li>Representation of a minor on the platform.</li>
            <li>Pornography and graphic violence.</li>
            <li>Malicious software or viruses.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Deciding to Subscribe to our Services</h2>
          <p className="p">Thenerdify.com offers a monthly subscription plan with premium features that can include VIP support, free extra services and bonuses added to your account that you can use to order the services within the paid billing period from the date of the latest payment. The subscription fee for the monthly plan is due on the same date for any month, or the closest date in that month, to the day you enrolled into the subscription and made your first monthly payment. The bonuses and additional perks included in your subscription plan are valid within the paid billing period from the date of the latest payment. Your subscription plan will be automatically renewed and Thenerdify.com will deduct monthly fees against your credit card until you cancel your membership. You may cancel your subscription at any time by contacting our support. In this case your bonuses remain unaffected for the remaining billing period but you will not be charged on the date of the recurring payment. If, for some reason, we are unable to process your credit card, you will receive an email notification. In this case we will try to process your payment for 2 weeks and then cancel your subscription until you update your card details and the payment can be processed.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Limitations of Liability</h2>
          <p className="p">The company and its third-party partners are not liable for any damages the following may have or may have not directly or indirectly caused:</p>
          <ul className="ul">
            <li>Services provided by Freelancers;</li>
            <li>Our content;</li>
            <li>Your online behavior;</li>
            <li>Company investigations/ authorized investigations;</li>
            <li>Any related action taken by copyright and intellectual property holders;</li>
            <li>Technical errors on our website;</li>
            <li>Damages to the User’s device, but this is not limited to online security breaches, bugs, glitches, and the like.</li>
          </ul>
          <p className="p">
            Geographic Limitations<br />
        The platform is owned by a company based in New Jersey, USA. We do not guarantee that the platform or its content is accessible or suitable for use outside the U.S. Access to the platform may be illegal in some countries or for certain individuals. If you access it from outside the U.S., you do so at your own risk and are responsible for complying with local laws.
          </p>
          <p className="p">
            Dispute Resolution<br />
        This section outlines the mandatory binding arbitration process and class action waiver. Claims, except for equitable relief claims, must be settled via binding arbitration. Any disputes not resolved informally must proceed through arbitration under the rules of the American Arbitration Association (AAA). Arbitration is individual, and parties waive rights to class actions or jury trials.
          </p>
          <p className="p">The full arbitration procedure is detailed, including rules for hearings, confidentiality, and time limits for filing claims. The agreement includes provisions for emergency relief, court jurisdiction, and the survival of the arbitration agreement beyond termination.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Disclaimer</h2>
          <p className="p">We are in charge of facilitating the interaction between you and the Freelancer. Agreements you set with the Freelancer are not within our control.</p>
          <p className="p">Freelancers are screened and checked by our team. The company does not verify the authenticity of our Freelancers' credentials, qualifications, and background. Meetups either physically or virtual are also not in our control. It is solely at your own risk.</p>
          <p className="p">You agree to the following provisions by accessing thenerdify.com:</p>
          <ul className="ul">
            <li>You can ask for legal assistance if it involves the Freelancer and third parties;</li>
            <li>You cannot seek legal help from us, as we do not control or take responsibility for the authenticity of the information supplied by the Freelancer, or even between the both of you.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Links and Third Party Services</h2>
          <p className="p">Using and clicking on external links provided by third parties is solely up to you, as thenerdify.com has not analyzed the content in each external link. We have no control over the contents of those sites or resources and accept no responsibility for them or for any loss or damage that may arise from your use of them.</p>
          <p className="p">Idemnity. To the fullest extent allowed by law, you agree to defend and indemnify the platform against any claims, losses, or damages arising from your interactions with freelancers on the platform, breaches of these Terms, disputes, misrepresentations, or your content. You will cooperate in the defense of such claims, and the platform reserves the right to assume full control of the defense. You cannot settle any claim without the platform's prior written consent.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Terms of Modifications</h2>
          <p className="p">This document can be modified at any given time without notifying the Users. You are bound by the current version of our Terms of Use.</p>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Freelancer’s Pledge</h2>
          <p className="p">As a Freelancer at Nerdify Platform, I am expected to uphold the company’s proper decorum and high standards. Therefore, I pledge to:</p>
          <ul className="ul">
            <li>Supply accurate information about my expertise and education to aid the User;</li>
            <li>Prohibit the User to participate in academic dishonesty and break US university regulations and state laws;</li>
            <li>Refuse to partake in any activity that allows the company to pay sums of money and royalties to third parties;</li>
            <li>Understand and acknowledge that my cooperation with the company will be terminated if I violate any terms in this document.</li>
          </ul>
        </div>

        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Intelectual Property</h2>
          <p className="p">The platform and all its content, including information, software, and media, are owned by the platform or its licensors and are protected by intellectual property laws. Company also holds exclusive rights to its trademarks, service marks, and logos. Users may not use or refer to these marks in any promotional or business context without the platform’s prior written consent.</p>
        </div>
        <div className="textPage-item">
          <h2 className="h3">Thenerdify.com's Other</h2>
          <p className="p">If any part of this Agreement is found to be invalid, illegal, or unenforceable, it will not affect the rest of the Agreement, which will remain in effect. The parties agree to replace the invalid term with one that is valid and as close as possible to the original intent. Any waiver of a breach must be in writing and will not waive future breaches. This Agreement is the full and exclusive agreement between the platform and you, replacing any prior agreements on the same subject matter.</p>
          <p className="p">If you have any questions regarding the platform, or any related Services, please contact us at <a className="a" href={`mailto:${REACT_APP_CONTACT_EMAIL}`} rel="noopener noreferrer">{REACT_APP_CONTACT_EMAIL}</a>. For information about how we collect, use, share and otherwise process information about you, please see our <NavLink className="a" to="/privacy-policy">Privacy Policy</NavLink> and <NavLink className="a" to="/cookies">Cookie Policy</NavLink>.</p>
        </div>

      </Holder>
    </TextPage>
  </LocalesPage>
);
