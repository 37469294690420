import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
//import { Link } from 'react-router-dom';
import Holder from './../../../../themes/nerdify/components/Holder';

import { HeaderCA, HeaderIconCA } from './../../../../themes/nerdify/components/Header';
import { HnavExt } from "../../../../themes/nerdify/components/Hnav";
import { HnavMobExt } from "../../../../themes/nerdify/components/HnavMob";
import { HeaderNavListExt, HeaderNavSubList } from "../../blocks/HeaderNav";

import { HeroNew, HeroImg  } from '../../../../themes/nerdify/components/Hero';

import {Section6, Section15, SectionBlock, Section11} from '../../../../themes/nerdify/components/Section';
import { Nerds2 } from '../../../../themes/nerdify/components/Nerds';
import {TextBack3} from '../../blocks/TextBack';
import { Intro3 } from '../../../../themes/nerdify/components/Intro';
import { Footer2 } from '../../blocks/Footer';

//import { InputSelectLocales } from './../../../../themes/nerdify/components/Form/InputSelect';
import LocalesPage from './../LocalesPage';
import { TopNerdsInfo2 } from '../../../../themes/nerdify/components/TopNerdsInfo';
import DemoSubjects from "../../../../themes/nerdify/components/DemoSubjects";
import {useMobile} from "../../../../hooks";
import DemoCards from "../../blocks/DemoCards";

const WithHook = (props) => {
  const isMobile = useMobile();

  return props.children({
    isMobile
  });
};

export default class AboutUs extends Component {
  render() {
    return (
      <WithHook>
        {({
            isMobile
          }) => {

    return (
      <LocalesPage {...this.props}>
        <HeaderCA type={3}>
          <Holder>
            <NavLink className="logo-link" to="/">
              <HeaderIconCA />
            </NavLink>
            <div className="header-wrap">
              {isMobile ? <HnavMobExt list={HeaderNavListExt} sublist={HeaderNavSubList} /> : <HnavExt list={HeaderNavListExt} /> }
            </div>
          </Holder>
        </HeaderCA>

        <HeroNew subTheme={6}>
          <div className="hero-main">
            {!isMobile && <HeroImg className="hero__img desktop-only" img={"new6.png"} img2x={"new6@2x.png"} />}
            <div className="holder">
              <h1 className="h0 hero__title">Nerdify is your<br /> sidekick to find the<br /> perfect study-work-<br className="desktop-only" />life balance!</h1>
              {isMobile && <HeroImg className="hero__img mobile-only" img={"new6-mob.png"} img2x={"new6-mob@2x.png"} />}
            </div>
          </div>
        </HeroNew>

          <Intro3 indent={'pt40m pb60m pt60d pb30d'}>
            <Holder className={'holder_3'}>
              <p className="h6 strong">
                Nerdify is a platform where business, students, and academia can get assistance with on-demand requests, tutoring, research, mentoring and the like from freelance Nerds with a wide expertise in different areas.
              </p>
              <p className="h6">
                It was created in September 2015 by 2 LSE graduates — Alex and Brian. Two friends were determined to create a user-friendly simple service that would allow them to connect peers in need with graduates with deep subject experience in a variety of fields. It began as a little university project for their friends and classmates, but it quickly became very popular. Nerdify has grown into one of the biggest platforms, providing excellent help with time-consuming tasks such as data collection, research, surveys, etc. Thousands of businesses, students, and academia worldwide get assistance from our Nerds every day!
              </p>
            </Holder>
          </Intro3>

          <Section6>
            <Holder className={'holder_1'} indent={'pt30m pb30m pt40d pb40d'}>
              <h2 className='h3'>Our mission</h2>
              <p className='p'>The team behind Nerdify aims to help businesses, students, and academia to invest time into achieving their goals:<br className="desktop-only"/> growing a successful business, getting a degree, and advancing their career instead of spending time doing routine tasks.</p>
            </Holder>
          </Section6>

        <Section11 indent={'mt40'}>
          <TopNerdsInfo2 titleTag={"h2"} />

          <DemoSubjects
            title={
              <>Our Nerds are graduates in <br className="mobile-only" />
                <span className="strong">75+ different fields</span>…</>
            }
          />

          <Nerds2 indent={'pt50 pb40'}>
            <div className="nerds__title">
              …from the{' '}
              <span className="strong">TOP universities</span> worldwide
            </div>
          </Nerds2>
        </Section11>

        <DemoCards titleTag={"h2"} />

        <SectionBlock theme={2} indent="pb80m pb70d">
          <Holder className="holder_3">
            <div className="section-title pb30">
              Send your personal request <br className="desktop-only" /> &
              we'll text you in 10 sec to help ASAP
            </div>
            <TextBack3 submitBtnTxt={'Text me now'} />
            {/*<TextBack4 submitBtnTxt={'Text me now'} subTheme={3} />*/}
          </Holder>
        </SectionBlock>

        <Footer2 />
      </LocalesPage>
    )
        }}
      </WithHook>
    )
  }
}
